import React, {useState} from "react";
import {Box, Button, Stack, Typography} from "@mui/material";
import axios from "axios";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {Delete} from "@mui/icons-material";

export const SendPhoto = ({handleSetMessage, handleSetError, handleSetSuccess, handleSetPage, handleSetSending}) => {
    const [foto, setFoto] = useState(null)
    const [url, setUrl] = useState('')

    const handleCompileFoto = (e) => {
        const [f] = e.target.files
        setFoto(f)
        setUrl(URL.createObjectURL(f))
    }

    const sendPhoto = async () => {
        handleSetSending(true)
        const formData = new FormData();
        formData.append('file', foto);
        await axios({
            url: 'https://davines-photo-api-qcc4npbf6q-ew.a.run.app/upload',
            method: 'POST',
            data: formData,
        }).then(() => {
            handleSetSuccess(true)
            setFoto(null)
            setUrl('')
            handleSetMessage("Photo sent")
        })
            .catch(() => {
                handleSetError(true)
                handleSetMessage("An error occurred, please try again")
            })
        handleSetSending(false)
    }
    return (
        <Box>
            <Typography variant={'h5'} fontWeight={'600'} textAlign={'center'} textTransform={'uppercase'}
                        color={'#333'}>Send your photo</Typography>
            <form style={{padding: '1rem 0'}}>
                <Stack direction={"column"} spacing={4}>
                    <Box sx={{
                        width: '100%',
                        height: '15.5rem',
                        fontWeight: '600',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        borderRadius: '3rem',
                        border: '2px dashed #333',
                        padding: '1rem'
                    }} component="label">
                        {foto === null ?
                            <Box>
                                <Typography variant={'h5'} color={'#333'}>Choose a photo</Typography>
                                <input type="file" hidden onChange={handleCompileFoto}/>
                            </Box> :
                            <Box>
                                <Box sx={{width:'11rem', height:'11rem', margin:'auto'}}>
                                    <img style={{width: '100%', height: '100%', objectFit:'cover'}} src={url}/>
                                </Box>
                                <Box>
                                    <Button startIcon={<Delete/>} onClick={() => {
                                        setTimeout(() => {
                                            setFoto(null)
                                            setUrl('')
                                        }, 100)
                                    }}>
                                        delete
                                    </Button>
                                </Box>
                            </Box>
                        }
                    </Box>
                    <Button onClick={sendPhoto} disabled={foto === null} className={'send-button'}
                            variant={"contained"}>
                        Send
                    </Button>
                </Stack>
            </form>
        </Box>
    )
}
