import React, {useState, useEffect} from "react";
import {Box, Button, Container, Grid, Switch, TextField, Typography} from "@mui/material";
import {db} from "../firebase/client";

export const Control = () => {
    const [password, setPassword] = useState('')
    const [logged, setLogged] = useState(false)
    const [visible, setVisible] = useState({
        photo: false,
        wordcloud: false,
        question: false,
    })

    useEffect(() => {
        getVisible()
    }, [])

    const getVisible = () => {
        db.ref('/control').on('value', (s) => {
            let res = s.val();
            setVisible(res)
        })
    }

    const handleChange = (event, type) => {
        db.ref('/control').update({
            [type]: event.target.checked
        }).then()
    }

    const handleSetPassword = (event) => {
        setPassword(event.target.value)
    }

    const handleLogIn = (e) => {
        e.preventDefault()
        if (password === 'parkour') {
            setLogged(true)
        }
    }

    return (
        <Box>
            {!logged &&
                <Container maxWidth={"xs"} sx={{pt:4, textAlign:'center'}}>
                    <form onSubmit={handleLogIn} style={{display:'flex', flexDirection:'column'}}>
                        <Typography variant={'h5'}>
                            Password
                        </Typography>
                        <TextField type={'password'} sx={{my:3}} value={password} onChange={handleSetPassword}/>
                        <Button variant={'contained'} type={"submit"}>Login</Button>
                    </form>
                </Container>
            }
            {logged &&
                <Container sx={{mt: 4}}>
                    <Grid container>
                        <Grid item xs={12} md={4}>
                            <Typography>Foto</Typography>
                            <Switch
                                checked={visible.photo}
                                onChange={(event) => handleChange(event, 'photo')}
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Typography>WordCloud</Typography>
                            <Switch
                                checked={visible.wordcloud}
                                onChange={(event) => handleChange (event, 'wordcloud')}
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Typography>Question</Typography>
                            <Switch
                                checked={visible.question}
                                onChange={(event) => handleChange (event, 'question')}
                            />
                        </Grid>
                    </Grid>
                </Container>
            }
        </Box>
    )
}
