import React, {useState, useEffect} from "react";
import {Box, Button} from "@mui/material";
import ReactWordcloud from "react-wordcloud";

import "tippy.js/dist/tippy.css";
import "tippy.js/animations/scale.css";
import {db} from "../firebase/client";
import {useList, useObject} from "react-firebase-hooks/database";

const options = {
    enableTooltip: false,
    deterministic: false,
    fontFamily: 'Crimson Text',
    fontSizes: [35, 200],
    fontStyle: "normal",
    fontWeight: "600",
    padding: 2,
    rotations: 0,
    rotationAngles: [0, 90],
    scale: "sqrt",
    spiral: "archimedean",
    transitionDuration: 1000
};

export const WordCloudTimed = () => {
    const [words, setWords] = useState([])
    const [colore, setColor] = useState('#000')

    const TIMER_MS = 3500;

    useEffect(()=>{
        const interval = setInterval(() => {
            updateWcData()
        }, TIMER_MS);

        return () => clearInterval(interval);
    },[])

    function updateWcData (){
        db.ref('/day2wc').get().then((s) => {
            let res = s.val();
            const bv = []
            Object.entries(res.parole).forEach((doc) => {
                const [key, value] = doc;
                bv.push({text: key, value: value})
            })
            setColor(res.colore)
            setWords(bv);
        })
    }

    return (
        <Box style={{width: '100%', height: '100vh'}}>
            <div style={{width: '100%', height: '100%'}}>
                <ReactWordcloud
                    options={{...options, colors: [colore]}}
                    words={words}/>
            </div>

        </Box>
    )
}
