import logo from './logo.svg';
import './App.css';
import {Home} from "./pages/Home";
import {CssBaseline, ThemeProvider} from "@mui/material";
import {davinesTheme} from "./theme/davinesTheme";
import {Route, Routes} from "react-router-dom";
import {WordCloud} from "./pages/WordCloud";
import {Control} from "./pages/Control";
import {WordCloudTimed} from "./pages/WordCloudTimed";
import {WordCloudTimedDynamic} from "./pages/WordCloudTimedDynamic";

function App() {
  return (
      <ThemeProvider theme={davinesTheme}>
          <CssBaseline/>
          <Routes>
              <Route element={<Home/>} path={'/'}/>
              <Route element={<WordCloud/>} path={'/word-cloud'}/>
              <Route element={<WordCloudTimed/>} path={'/word-cloud-timed'}/>
              <Route element={<WordCloudTimedDynamic/>} path={'/word-cloud-timed-dynamic'}/>
              <Route element={<Control/>} path={'/control'}/>
          </Routes>

      </ThemeProvider>
  );
}

export default App;
