// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import firebase from 'firebase/compat/app'
import 'firebase/compat/database'
import 'firebase/compat/firestore'
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyAYkn5eEm5dNfHP5jmYJ6b6vh91fkGiXcI",
    authDomain: "davines-2022.firebaseapp.com",
    databaseURL: "https://davines-2022-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "davines-2022",
    storageBucket: "davines-2022.appspot.com",
    messagingSenderId: "104301077437",
    appId: "1:104301077437:web:55bbf4931b5e93a0bf68d4",
    measurementId: "G-8343RZP6E8"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig)
const db = firebase.database()

export {db}
