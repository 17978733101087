import {createTheme} from "@mui/material";

export const davinesTheme = createTheme({
    typography:{
        fontFamily:['Crimson Text']
    },
    components: {
        MuiCard: {
            variants:[
                {
                    props:{variant:'domanda'},
                    style:{
                        background: '#fff',
                        color:'#151515',
                        borderRadius:0
                    }
                }
            ]
        },

        MuiButton: {
            styleOverrides:{
                root:{
                    borderRadius:0,
                    width:'14rem',
                    height:'3rem',
                    alignSelf:'center'
                }
            }
        }
    },
    palette:{
        mode:'light',
        primary:{
            main: '#4e4b4a'
        },
        background:{
            default:'#f4f4f6'
        }
    }
})
