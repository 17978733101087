import React, {useEffect, useState} from "react";
import {
    Backdrop,
    Box,
    Button,
    Card,
    CircularProgress,
    Collapse,
    Container,
    Link,
    Snackbar,
    Stack,
    TextField,
    Typography
} from "@mui/material";
import logo from '../assets/logo.png'
import axios from "axios";
import MuiAlert from '@mui/material/Alert';
import {SendPhoto} from "../components/SendPhoto";
import {SendWord} from "../components/SendWord";
import {db} from "../firebase/client";

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export const Home = () => {
    const [success, setSuccess] = useState(false)
    const [error, setError] = useState(false)
    const [message, setMessage] = useState('')
    const [page, setPage] = useState('home')
    const [sending, setSending] = useState(false)
    const [visible, setVisible] = useState({
        photo: true,
        wordcloud: false
    })

    useEffect(() => {
        getVisible()
    }, [])

    const getVisible = () => {
        db.ref('/control').on('value', (s) => {
            let res = s.val();
            setVisible(res)
        })
    }

    const handleSetMessage = (content) => {
        setMessage(content)
    }

    const handleSetSending = (bool) => {
      setSending(bool)
    }

    const handleSetError = (bool) => {
        setError(bool)
    }

    const handleSetSuccess = (bool) => {
        setSuccess(bool)
    }

    const handleSetPage = (p) => {
        setPage(p)
    }

    return (
        <main>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={sending}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <Stack className={'home'} spacing={4} direction={"column"} alignItems={"center"} sx={{pt: 4}}>
                <img src={logo} style={{width: '100%', maxWidth: '6rem'}}/>
                <Snackbar open={success} autoHideDuration={3000} onClose={() => setSuccess(false)}>
                    <Alert onClose={() => setSuccess(false)} severity="success"
                           sx={{width: '100%', border: 'none', fontWeight: '600'}}>
                        {message}
                    </Alert>
                </Snackbar>
                <Snackbar open={error} autoHideDuration={3000} onClose={() => setError(false)}>
                    <Alert onClose={() => setError(false)} severity="error"
                           sx={{width: '100%', border: 'none', fontWeight: '600'}}>
                        {message}
                    </Alert>
                </Snackbar>
                <Container maxWidth={'sm'}>
                    <Card variant={'domanda'} sx={{p: 4, textAlign: 'left'}}>
                        <SendPhoto handleSetMessage={handleSetMessage} handleSetError={handleSetError}
                                       handleSetSuccess={handleSetSuccess} handleSetPage={handleSetPage} handleSetSending={handleSetSending}/>
                    </Card>
                </Container>
            </Stack>
        </main>

    )
}
